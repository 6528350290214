l
<template>
  <Form v-if="targets.length" :heading1="'System Settings'">
    <template v-slot:details>
      <div class="md:flex md:space-x-12 block space-x-0">
        <div class="flex-1">
          <TextInput
            :label="'Target'"
            :defaultValue="targets[0].id"
            :types="'select'"
            @input="setTarget"
            :options="targets"
          />
        </div>

        <div class="flex-1">
          <div class="textInput">
            <label :for="'fieldsContainer'" :class="`label`"
              >Fields to display
              <span>*</span></label
            >
            <div id="fieldsContainer" class="d-flex">
              <div
                v-for="(field, i) in fields"
                :key="i"
                @click="updateField(i)"
                :class="{ active: field.value }"
                class="field d-flex"
              >
                <div class="content">{{ field.name }}</div>
                <div class="icons ml-auto">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    v-if="!field.value"
                  >
                    <path
                      d="M7.00001 13.6667C3.31801 13.6667 0.333344 10.682 0.333344 7C0.333344 3.318 3.31801 0.333336 7.00001 0.333336C10.682 0.333336 13.6667 3.318 13.6667 7C13.6667 10.682 10.682 13.6667 7.00001 13.6667ZM7.00001 6.05734L5.11468 4.17134L4.17134 5.11467L6.05734 7L4.17134 8.88534L5.11468 9.82867L7.00001 7.94267L8.88534 9.82867L9.82868 8.88534L7.94268 7L9.82868 5.11467L8.88534 4.17134L7.00001 6.05734Z"
                      fill="#032B3D"
                    />
                  </svg>

                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    v-else
                  >
                    <path
                      d="M5.65 11.1917C2.58934 11.1917 0.108337 8.71066 0.108337 5.65C0.108337 2.58933 2.58934 0.10833 5.65 0.10833C8.71067 0.10833 11.1917 2.58933 11.1917 5.65C11.1917 8.71066 8.71067 11.1917 5.65 11.1917ZM5.0975 7.86666L9.01546 3.94815L8.23187 3.16456L5.0975 6.29948L3.52976 4.73174L2.74617 5.51533L5.0975 7.86666Z"
                      fill="#032B3D"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Button :first-l="'Cancel'" :last-l="'Save'" @action="saveSettings(settings.data)" />
    </template>
  </Form>
</template>
<script>
import Button from "/src/components/form/Buttons.vue";
import TextInput from "/src/components/form/TextInput.vue";
import Form from "/src/components/form/Form.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "Settings",
  components: {
    Button,
    TextInput,
    Form,
  },
  data() {
    return {
      fields: [],
      selectedTarget: null,
    };
  },
  methods: {
    ...mapActions({
      saveSettings: "settings/saveSettings",
    }),
    setTarget(value) {
      this.selectedTarget = value;
    },
    updateField(index) {
      this.settings.data[this.selectedTarget][this.fields[index].id] =
        !this.settings.data[this.selectedTarget][this.fields[index].id];
        this.fields[index].value = !this.fields[index].value
    },
  },
  watch: {
    selectedTarget() {
      this.fields = Object.keys(this.settings.data[this.selectedTarget]).map(
        (x) => {
          let str = x.split("_").join(" ");
          return {
            id: x,
            value: this.settings.data[this.selectedTarget][x],
            name: str.charAt(0).toUpperCase() + str.slice(1),
          };
        }
      );
    },
  },
  computed: {
    ...mapState({ settings: "settings" }),
    targets() {
      if (this.settings.data)
        return Object.keys(this.settings.data).map((x) => {
          let str = x.split("_").join(" ");
          return {
            id: x,
            name: str.charAt(0).toUpperCase() + str.slice(1),
          };
        });
      return [];
    },
  },
  async created() {
    while (!this.targets.length) {
      await new Promise((r) => setTimeout(r, 2000));
    }
    this.selectedTarget = this.targets[0].id;
  },
};
</script>